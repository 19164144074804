<template>
	<el-container>
		<ClassRoomNavbar
			:activeCenterMode="activeCenterMode"
			@addClassRoom="addClassRoom"
			@rerenderPage="initData"
		/>
		<div class="d-flex">
			<TagLeftBar v-if="getShowLeftBar" />
			<div class="w-100" :style="{ 'padding-left': getShowLeftBarStyle }">
				<el-main
					class="w-100"
					v-if="isClassRoomCenterShow"
					@mouseover="$store.state.LeftBarContentShow = false"
				>
					<el-dialog
						title="設定自定背景"
						v-model="classBackgroundDialog"
						:fullscreen="true"
						:before-close="handleClose"
						@close="setClassObj.backgroundImg = backupImage"
					>
						<div>
							<p class="">系統預設</p>
							<div class="my-2 d-flex justify-content-center">
								<div
									v-for="img in defaultBgImages"
									:key="img"
									class="block mx-1 defaultBgImage"
									@click="setClassObj.backgroundImg = img"
								>
									<el-image
										style="width: 60px; height: 60px"
										:src="img"
										fit="fill"
									/>
								</div>
							</div>
							<p class="">自定上傳</p>
							<div class="my-2">
								<el-upload
									:style="{
										width: '300px',
									}"
									class="upload-demo mx-auto"
									drag
									:auto-upload="false"
									@change="uploadImage"
									accept="image/*"
									:show-file-list="false"
								>
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">
										將圖片拖到此處，或<em>點擊上傳</em>
									</div>
									<div class="el-upload__tip" :v-slot="tip">
										只能上傳jpg/png文件
									</div>
								</el-upload>
							</div>
							<vue-picture-cropper
								v-if="imageToCrop"
								:boxStyle="{
									width: '300px ',
									height: '300px',
									backgroundColor: '#f8f8f8',
									margin: 'auto',
								}"
								:img="imageToCrop"
								:options="{
									viewMode: 1,
									dragMode: 'move',
									preview: preview,
									aspectRatio: 7 / 1,
									cropBoxResizable: true,
								}"
								@ready="getCropResult"
							></vue-picture-cropper>

							<div class="mx-auto my-2">
								<el-button
									v-if="imageToCrop"
									@click="getCropResult"
									size="small"
									>檢視結果</el-button
								>
							</div>

							<el-col
								class="mx-auto"
								:style="{ width: '300px' }"
								:lg="6"
								:md="12"
								:xs="24"
							>
								<el-card
									:body-style="{
										padding: '0px',
										'background-color': setClassObj.backgroundColor,
									}"
									shadow="hover"
								>
									<div class="classCard">
										<div
											class="classHeader"
											:style="backgroundImgInit(setClassObj.backgroundImg)"
										>
											<div class="classNameDiv">
												<h3 class="className">教室名稱</h3>
											</div>
										</div>
										<div class="classCreator">
											<p class="creatorName">範例</p>
										</div>
										<div class="roleIdArea">
											<el-tag size="small" type="info">
												<span>老師</span>
											</el-tag>
											<el-tag size="small" type="info" style="border: none">
												<span>建立者</span>
											</el-tag>
										</div>
										<div class="otherTool">
											<div class="role">
												<el-tag type="info" style="border: none">
													<span>範例</span>
												</el-tag>
											</div>
										</div>
									</div>
								</el-card>
							</el-col>

							<div class="mx-auto my-2">
								<el-button @click="setClassImage" size="small" type="primary"
									>更換</el-button
								>
								<el-button
									@click="
										setClassObj.backgroundImg = '';
										setClassImage();
									"
									size="small"
									>返回預設</el-button
								>
							</div>
						</div>
					</el-dialog>
					<div class="container d-flex flex-column" style="padding: 0">
						<div class="center" v-if="!activeCenterMode">
							<el-breadcrumb
								v-if="$route.name == 'ChargeMeContactBook_ClassRoomCenter'"
								separator="/"
								class="mb-2"
							>
								<el-breadcrumb-item
									:to="{ path: '/ChargeMeContactBook/ClassRoomCenter' }"
									@click="$store.state.selectTagId = 'All'"
									>課程選單</el-breadcrumb-item
								>
								<el-breadcrumb-item v-if="$store.state.selectTagId == 'trash'"
									>垃圾桶</el-breadcrumb-item
								>
								<el-breadcrumb-item v-if="$store.state.selectTagId == 'inbox'"
									>封存</el-breadcrumb-item
								>
								<el-breadcrumb-item
									v-if="!excludeTags.includes($store.state.selectTagId)"
									>標籤：{{
										getUserTagsName($store.state.selectTagId)
									}}</el-breadcrumb-item
								>
							</el-breadcrumb>
							<div
								v-show="isEmptyShow && $store.state.selectTagId == 'All'"
								class="d-flex flex-wrap align-items-center"
							>
								<p
									class="col-12 col-md-6 text-start mb-2"
									style="color: #514832 !important"
								>
									<span class="fw-bold me-2" style="font-size: small"
										>Hi&emsp;{{
											$store.state.auth.user.name
										}}&emsp;歡迎回來</span
									>
									<span style="font-size: xx-small">準備交作業囉！</span>
								</p>
								<div
									class="ms-md-auto py-2 d-flex align-items-center me-2"
									:class="
										IsFilterClass
											? 'searchClass border border-1 rounded shadow-sm  mb-2'
											: ''
									"
								>
									<el-input
										v-if="IsFilterClass"
										size="small"
										class="inline-input searchInput me-2"
										v-model="searchAddClassInput"
										placeholder="課程關鍵字"
										:trigger-on-focus="false"
									>
									</el-input>
									<el-button
										class="text-white centerBtn"
										@click="
											IsFilterClass = !IsFilterClass;
											searchAddClassInput = '';
										"
										size="small"
										round
									>
										<i
											:class="IsFilterClass ? 'fa-times' : 'fa-search'"
											class="fas me-2"
										></i>
										<span>{{ IsFilterClass ? "關閉" : "篩選" }}</span>
									</el-button>
								</div>
								<div
									class="py-2 d-flex align-items-center"
									:class="
										IsSearchingClass
											? 'searchClass border border-1 rounded shadow-sm'
											: ''
									"
								>
									<el-autocomplete
										v-if="IsSearchingClass"
										size="small"
										class="inline-input searchInput me-2"
										v-model="searchApplyClassInput"
										:fetch-suggestions="querySearch"
										placeholder="課程資訊"
										:trigger-on-focus="false"
										@input="showApplyBtn = false"
										@select="handleSelectClass"
									>
										<template v-slot="{ item }">
											<div class="w-100 d-flex align-items-center py-2">
												<el-image
													style="width: 60px; height: 20px"
													:src="item.BgImg"
												>
													<template #error>
														<div
															style="
																width: 60px;
																height: 20px;
																background-color: rgb(237, 237, 237);
															"
														></div>
													</template>
												</el-image>
												<div class="ms-2">
													<div style="line-height: normal">
														<span class="d-block">{{ item.value }}</span>
														<span>{{ item.name }}</span>
													</div>
												</div>
											</div>
										</template>
									</el-autocomplete>
									<el-button
										class="text-white centerBtn"
										@click="applyToClass"
										size="small"
										round
									>
										<i
											:class="IsSearchingClass ? 'fa-search' : 'fa-plus'"
											class="fas me-2"
										></i>
										<span>{{
											IsSearchingClass ? "要求加入" : "課程代碼"
										}}</span>
									</el-button>
									<el-button
										v-if="IsSearchingClass"
										class="text-white"
										style="background-color: #ffd964 !important; border: none"
										size="small"
										round
										@click="
											IsSearchingClass = false;
											searchApplyClassInput = '';
										"
									>
										<i class="fas fa-times me-2"></i>
										<span>關閉</span>
									</el-button>
								</div>
								<el-button
									v-if="
										$store.state.selectTagId != 'inbox' &&
										$store.state.selectTagId != 'trash'
									"
									class="text-white ms-2 my-2 centerBtn addCenterBtn"
									round
									size="small"
									@click="addClassRoom"
								>
									<i class="fas fa-plus me-2"></i>
									<span>新增課程</span>
								</el-button>
								<el-dropdown>
									<el-button
										class="text-white ms-2 my-2 centerBtn"
										round
										size="small"
									>
										<sapn>{{ filterRole }}</sapn>
										<i class="ms-2 fa-solid fa-bars-filter"></i
									></el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item @click="rolehandleClick('All')"
												>全部</el-dropdown-item
											>
											<el-dropdown-item @click="rolehandleClick('Teacher')"
												>老師</el-dropdown-item
											>
											<el-dropdown-item @click="rolehandleClick('Student')"
												>學生</el-dropdown-item
											>
											<el-dropdown-item @click="rolehandleClick('Parent')"
												>家長</el-dropdown-item
											>
											<el-dropdown-item @click="rolehandleClick('isOwner')"
												>建立</el-dropdown-item
											>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</div>

							<div>
								<div
									class="empty_class py-2"
									v-show="isEmptyShow && $store.state.selectTagId == 'All'"
								>
									<el-row :gutter="20">
										<el-col :lg="6" :md="12" :xs="24">
											<el-card
												:body-style="{
													padding: '40px 0',
													width: '100%',
												}"
												shadow="hover"
												@click="addClassRoom"
											>
												<i
													class="fad fa-plus-circle"
													style="
														--fa-primary-color: #ffffff;
														--fa-secondary-color: #f7e6b2;
														--fa-secondary-opacity: 1;
														font-size: 40px !important;
													"
												></i>
												<p class="mt-3 fw-bold" style="color: rgb(81, 72, 50)">
													新增課程
												</p>
											</el-card>
										</el-col>
									</el-row>
								</div>
								<el-skeleton v-if="!isloaded" />
								<div v-else class="class" v-show="!isEmptyShow">
									<div>
										<div
											class="d-flex justify-content-start py-2 align-items-center"
										>
											<p
												v-if="
													filteredData.filter((x) => x.isTop && !x.isArchive)
														.length > 0 &&
													$store.state.selectTagId != 'inbox' &&
													$store.state.selectTagId != 'trash'
												"
												class="text-start"
												style="font-size: xx-small"
											>
												已固定
											</p>
										</div>
										<el-row :gutter="20">
											<template
												v-for="o in filteredData.filter(
													(x) => x.isTop && !x.isArchive && !x.isTempDeleted
												)"
											>
												<el-col
													:lg="6"
													:md="12"
													:xs="24"
													v-if="
														$store.state.selectTagId == 'All' ||
														$store.state.selectTagId == '' ||
														($store.state.selectTagId != '' &&
															o.tags.indexOf($store.state.selectTagId)) != -1
													"
													:key="o"
												>
													<el-card
														:body-style="{
															padding: '0px',
															'background-color': o.backgroundColor,
														}"
														shadow="hover"
													>
														<div class="classCard">
															<div
																class="classHeader"
																:style="backgroundImgInit(o.backgroundImg)"
															>
																<div
																	class="classNameDiv"
																	@click="toClassRoom(o)"
																>
																	<h3 class="className">{{ o.className }}</h3>
																</div>
																<i
																	v-if="o.isTop"
																	class="fas fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<i
																	v-else
																	class="fal fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<div class="roleIdArea">
																	<el-tag
																		class="class_id"
																		size="small"
																		type="info"
																		>代碼: {{ o.id }}</el-tag
																	>
																	<div class="role">
																		<span class="me-1"
																			>我的角色<i
																				class="fal fa-caret-right ms-1"
																			></i
																		></span>
																		<el-tag
																			size="small"
																			v-if="
																				o.role == 'Teacher' ||
																				o.role == 'Student' ||
																				o.role == 'Parent'
																			"
																			type="info"
																		>
																			<span v-if="o.role == 'Teacher'"
																				>老師</span
																			>
																			<span v-else-if="o.role == 'Student'"
																				>學生</span
																			>
																			<span v-else-if="o.role == 'Parent'"
																				>家長</span
																			>
																		</el-tag>
																		<el-tag
																			v-if="o.isOwner"
																			size="small"
																			type="info"
																			style="border: none"
																		>
																			<span>建立者</span>
																		</el-tag>
																	</div>
																</div>
															</div>

															<div class="classCreator">
																<template
																	v-if="
																		o.role == 'Student' || o.role == 'Parent'
																	"
																>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			老師
																		</el-tag>
																	</div>
																	<el-avatar
																		size="small"
																		:src="o.teacher.avatar"
																		class="me-1"
																	></el-avatar>
																	<p class="creatorName">
																		{{ o.teacher.name }}
																	</p>
																</template>
																<template v-else>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			學生
																		</el-tag>
																	</div>
																	<el-avatar
																		v-for="s in o.studentAvatarAndNames"
																		:key="s"
																		size="small"
																		class="me-1"
																		:src="s.avatar"
																	></el-avatar>
																</template>
															</div>
															<div class="tagsArea" v-if="o.tags != null">
																<el-tag
																	v-for="item in o.tags"
																	:key="item"
																	size="small"
																	class="mb-1"
																>
																	{{ getUserTagsName(item) }}
																</el-tag>
															</div>

															<div class="otherTool">
																<div>
																	<!-- <i v-if="o.role == 'Student'" class="fal fa-map-marker-check" title="打卡" @click="createClassRoomCheckIn(o)"></i>
                                  <i v-if="setPermission(o.permission)" class="fal fa-comment-alt-edit" title="編輯" @click="editClassRoom(o)"></i>
                                  <i v-if="setPermission(o.permission)" class="fal fa-copy" title="複製" @click="copyClassRoom(o)"></i>
                                  <i v-if="setPermission(o.permission)" class="fal fa-image" title="設定背景" @click="setClassImgBtn(o)"></i> -->

																	<i
																		class="fal fa-info-circle"
																		title="課程資訊"
																		@click="toClassRoomByActiveName(o, 'zero')"
																	></i>
																	<i
																		class="fal fa-books"
																		title="聯絡簿"
																		@click="toClassRoomByActiveName(o, 'first')"
																	></i>
																	<i
																		class="fal fa-users"
																		title="成員"
																		@click="toClassRoomByActiveName(o, 'third')"
																	></i>
																	<i
																		v-if="o.role == 'Student'"
																		class="fal fa-map-marker-check"
																		title="打卡"
																		@click="createClassRoomCheckIn(o)"
																	></i>
																	<el-dropdown>
																		<span class="el-dropdown-link">
																			<i
																				class="fal fa-fill-drip"
																				title="設定卡片顏色"
																			></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu :v-slot="dropdown">
																				<el-dropdown-item
																					v-for="i in predefineColors"
																					:key="i"
																					@click="
																						o.backgroundColor = i;
																						colorChange(o);
																					"
																				>
																					<div
																						class="colorCircle"
																						:style="{ 'background-color': i }"
																					></div>
																				</el-dropdown-item>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																	<el-dropdown trigger="click">
																		<span class="el-dropdown-link">
																			<i class="fal fa-angle-down"></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="editClassRoom(o)"
																					>編輯名稱</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="copyClassRoom(o)"
																					>複製課程</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="setClassImgBtn(o)"
																					>設定背景</el-dropdown-item
																				>
																				<el-dropdown-item
																					@click="
																						dialogFormVisible = true;
																						selectClass = o;
																					"
																					>新增標籤</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="o.isArchive"
																					@click="archiveClassRoom(o)"
																					>解除封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-else
																					@click="archiveClassRoom(o)"
																					>封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="
																						setPermission(o.permission) &&
																						o.isOwner
																					"
																					@click="deleteClassRoom(o)"
																					>移至垃圾桶</el-dropdown-item
																				>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																</div>
																<div class="role">
																	<el-tag
																		v-if="o.isCheckInToday"
																		type="info"
																		style="
																			border: none;
																			font-size: 10px;
																			padding: 2px;
																		"
																	>
																		<span>今日已打卡</span>
																	</el-tag>
																</div>
															</div>
														</div>
													</el-card>
												</el-col>
											</template>
										</el-row>
									</div>
									<div>
										<p
											v-if="
												filteredData.filter((x) => !x.isTop && !x.isArchive)
													.length > 0 &&
												$store.state.selectTagId != 'inbox' &&
												$store.state.selectTagId != 'trash'
											"
											class="text-start py-2"
											style="font-size: xx-small"
										>
											其他課程
										</p>
										<el-row :gutter="20">
											<template
												v-for="o in filteredData.filter(
													(x) => !x.isTop && !x.isArchive && !x.isTempDeleted
												)"
											>
												<el-col
													:body-style="{ padding: '0px' }"
													shadow="hover"
													v-if="
														$store.state.selectTagId == '' ||
														($store.state.selectTagId != '' &&
															o.tags.indexOf($store.state.selectTagId) != -1) ||
														$store.state.selectTagId == 'All'
													"
													:lg="6"
													:md="12"
													:xs="24"
													:key="o"
												>
													<el-card
														:body-style="{
															padding: '0px',
															'background-color': o.backgroundColor,
														}"
														shadow="hover"
													>
														<div class="classCard">
															<div
																class="classHeader"
																:style="backgroundImgInit(o.backgroundImg)"
															>
																<div
																	class="classNameDiv"
																	@click="toClassRoom(o)"
																>
																	<h3 class="className">{{ o.className }}</h3>
																</div>
																<i
																	v-if="o.isTop"
																	class="fas fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<i
																	v-else
																	class="fal fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<div class="roleIdArea">
																	<el-tag
																		class="class_id"
																		size="small"
																		type="info"
																		>代碼: {{ o.id }}</el-tag
																	>
																	<div class="role">
																		<span class="me-1"
																			>我的角色<i
																				class="fal fa-caret-right ms-1"
																			></i
																		></span>
																		<el-tag
																			size="small"
																			v-if="
																				o.role == 'Teacher' ||
																				o.role == 'Student' ||
																				o.role == 'Parent'
																			"
																			type="info"
																		>
																			<span v-if="o.role == 'Teacher'"
																				>老師</span
																			>
																			<span v-else-if="o.role == 'Student'"
																				>學生</span
																			>
																			<span v-else-if="o.role == 'Parent'"
																				>家長</span
																			>
																		</el-tag>
																		<el-tag
																			v-if="o.isOwner"
																			size="small"
																			type="info"
																			style="border: none"
																		>
																			<span>建立者</span>
																		</el-tag>
																	</div>
																</div>
															</div>

															<div class="classCreator">
																<template
																	v-if="
																		o.role == 'Student' || o.role == 'Parent'
																	"
																>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			老師
																		</el-tag>
																	</div>
																	<el-avatar
																		size="small"
																		:src="o.teacher.avatar"
																		class="me-1"
																	></el-avatar>
																	<p class="creatorName">
																		{{ o.teacher.name }}
																	</p>
																</template>
																<template v-else>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			學生
																		</el-tag>
																	</div>
																	<el-avatar
																		v-for="s in o.studentAvatarAndNames"
																		:key="s"
																		size="small"
																		class="me-1"
																		:src="s.avatar"
																	></el-avatar>
																</template>
															</div>

															<div class="tagsArea" v-if="o.tags != null">
																<el-tag
																	v-for="item in o.tags"
																	:key="item"
																	size="small"
																	class="mb-1"
																>
																	{{ getUserTagsName(item) }}
																</el-tag>
															</div>

															<div class="otherTool">
																<div>
																	<i
																		class="fal fa-info-circle"
																		title="課程資訊"
																		@click="toClassRoomByActiveName(o, 'zero')"
																	></i>
																	<i
																		class="fal fa-books"
																		title="聯絡簿"
																		@click="toClassRoomByActiveName(o, 'first')"
																	></i>
																	<i
																		class="fal fa-users"
																		title="成員"
																		@click="toClassRoomByActiveName(o, 'third')"
																	></i>
																	<i
																		v-if="o.role == 'Student'"
																		class="fal fa-map-marker-check"
																		title="打卡"
																		@click="createClassRoomCheckIn(o)"
																	></i>
																	<el-dropdown>
																		<span class="el-dropdown-link">
																			<i
																				class="fal fa-fill-drip"
																				title="設定卡片顏色"
																			></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu :v-slot="dropdown">
																				<el-dropdown-item
																					v-for="i in predefineColors"
																					:key="i"
																					@click="
																						o.backgroundColor = i;
																						colorChange(o);
																					"
																				>
																					<div
																						class="colorCircle"
																						:style="{ 'background-color': i }"
																					></div>
																				</el-dropdown-item>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																	<el-dropdown trigger="click">
																		<span class="el-dropdown-link">
																			<i class="fal fa-angle-down"></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="editClassRoom(o)"
																					>編輯名稱</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="copyClassRoom(o)"
																					>複製課程</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="setClassImgBtn(o)"
																					>設定背景</el-dropdown-item
																				>
																				<el-dropdown-item
																					@click="
																						dialogFormVisible = true;
																						selectClass = o;
																					"
																					>新增標籤</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="o.isArchive"
																					@click="archiveClassRoom(o)"
																					>解除封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-else
																					@click="archiveClassRoom(o)"
																					>封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="
																						setPermission(o.permission) &&
																						o.isOwner
																					"
																					@click="deleteClassRoom(o)"
																					>移至垃圾桶</el-dropdown-item
																				>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																</div>
																<div class="role">
																	<el-tag
																		v-if="o.isCheckInToday"
																		class="ms-2"
																		type="info"
																		style="border: none"
																	>
																		<span>今日已打卡</span>
																	</el-tag>
																</div>
															</div>
														</div>
													</el-card>
												</el-col>
											</template>
										</el-row>
									</div>
									<div>
										<p
											class="text-start py-2"
											style="font-size: xx-small"
											v-if="$store.state.selectTagId == 'inbox'"
										>
											已封存
										</p>
										<el-row :gutter="20">
											<template
												v-for="o in filteredData.filter(
													(x) => x.isArchive && !x.isTempDeleted
												)"
											>
												<el-col
													:lg="6"
													:md="12"
													:xs="24"
													v-if="$store.state.selectTagId == 'inbox'"
													:key="o"
												>
													<el-card
														:body-style="{
															padding: '0px',
															'background-color': o.backgroundColor,
														}"
														shadow="hover"
													>
														<div class="classCard">
															<div
																class="classHeader"
																:style="backgroundImgInit(o.backgroundImg)"
															>
																<div
																	class="classNameDiv"
																	@click="toClassRoom(o)"
																>
																	<h3 class="className">{{ o.className }}</h3>
																</div>
																<i
																	v-if="o.isTop"
																	class="fas fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<i
																	v-else
																	class="fal fa-thumbtack"
																	@click="topClassRoom(o)"
																></i>
																<div class="roleIdArea">
																	<el-tag
																		class="class_id"
																		size="small"
																		type="info"
																		>代碼: {{ o.id }}</el-tag
																	>
																	<div class="role">
																		<span class="me-1"
																			>我的角色<i
																				class="fal fa-caret-right ms-1"
																			></i
																		></span>
																		<el-tag
																			size="small"
																			v-if="
																				o.role == 'Teacher' ||
																				o.role == 'Student' ||
																				o.role == 'Parent'
																			"
																			type="info"
																		>
																			<span v-if="o.role == 'Teacher'"
																				>老師</span
																			>
																			<span v-else-if="o.role == 'Student'"
																				>學生</span
																			>
																			<span v-else-if="o.role == 'Parent'"
																				>家長</span
																			>
																		</el-tag>
																		<el-tag
																			v-if="o.isOwner"
																			size="small"
																			type="info"
																			style="border: none"
																		>
																			<span>建立者</span>
																		</el-tag>
																	</div>
																</div>
															</div>

															<div class="classCreator">
																<template
																	v-if="
																		o.role == 'Student' || o.role == 'Parent'
																	"
																>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			老師
																		</el-tag>
																	</div>
																	<el-avatar
																		size="small"
																		:src="o.teacher.avatar"
																		class="me-1"
																	></el-avatar>
																	<p class="creatorName">
																		{{ o.teacher.name }}
																	</p>
																</template>
																<template v-else>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			學生
																		</el-tag>
																	</div>
																	<el-avatar
																		v-for="s in o.studentAvatarAndNames"
																		:key="s"
																		size="small"
																		class="me-1"
																		:src="s.avatar"
																	></el-avatar>
																</template>
															</div>

															<div class="tagsArea" v-if="o.tags != null">
																<el-tag
																	v-for="item in o.tags"
																	:key="item"
																	size="small"
																	class="mb-1"
																>
																	{{ getUserTagsName(item) }}
																</el-tag>
															</div>
															<div class="otherTool">
																<div>
																	<i
																		class="fal fa-info-circle"
																		title="課程資訊"
																		@click="toClassRoomByActiveName(o, 'zero')"
																	></i>
																	<i
																		class="fal fa-books"
																		title="聯絡簿"
																		@click="toClassRoomByActiveName(o, 'first')"
																	></i>
																	<i
																		class="fal fa-users"
																		title="成員"
																		@click="toClassRoomByActiveName(o, 'third')"
																	></i>
																	<i
																		v-if="o.role == 'Student'"
																		class="fal fa-map-marker-check"
																		title="打卡"
																		@click="createClassRoomCheckIn(o)"
																	></i>
																	<el-dropdown>
																		<span class="el-dropdown-link">
																			<i
																				class="fal fa-fill-drip"
																				title="設定卡片顏色"
																			></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu :v-slot="dropdown">
																				<el-dropdown-item
																					v-for="i in predefineColors"
																					:key="i"
																					@click="
																						o.backgroundColor = i;
																						colorChange(o);
																					"
																				>
																					<div
																						class="colorCircle"
																						:style="{ 'background-color': i }"
																					></div>
																				</el-dropdown-item>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																	<el-dropdown trigger="click">
																		<span class="el-dropdown-link"
																			><i class="fal fa-angle-down"></i>
																		</span>
																		<template #dropdown>
																			<el-dropdown-menu>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="editClassRoom(o)"
																					>編輯名稱</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="copyClassRoom(o)"
																					>複製課程</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="setPermission(o.permission)"
																					@click="setClassImgBtn(o)"
																					>設定背景</el-dropdown-item
																				>
																				<el-dropdown-item
																					@click="
																						dialogFormVisible = true;
																						selectClass = o;
																					"
																					>新增標籤</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="o.isArchive"
																					@click="archiveClassRoom(o)"
																					>解除封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-else
																					@click="archiveClassRoom(o)"
																					>封存</el-dropdown-item
																				>
																				<el-dropdown-item
																					v-if="
																						setPermission(o.permission) &&
																						o.isOwner
																					"
																					@click="deleteClassRoom(o)"
																					>移至垃圾桶</el-dropdown-item
																				>
																			</el-dropdown-menu>
																		</template>
																	</el-dropdown>
																</div>
																<div class="role">
																	<el-tag
																		v-if="o.isCheckInToday"
																		class="ms-2"
																		type="info"
																		style="border: none"
																	>
																		<span>今日已打卡</span>
																	</el-tag>
																</div>
															</div>
														</div>
													</el-card>
												</el-col>
											</template>
										</el-row>
									</div>
									<div>
										<p
											class="text-start py-2"
											style="font-size: xx-small"
											v-if="$store.state.selectTagId == 'trash'"
										>
											已刪除
										</p>
										<el-row :gutter="20">
											<template
												v-for="o in filteredData.filter(
													(x) => x.isTempDeleted && x.isOwner
												)"
											>
												<el-col
													:lg="6"
													:md="12"
													:xs="24"
													v-if="$store.state.selectTagId == 'trash'"
													:key="o"
												>
													<el-card
														:body-style="{
															padding: '0px',
															'background-color': o.backgroundColor,
														}"
														shadow="hover"
													>
														<div class="classCard">
															<div
																class="classHeader"
																:style="backgroundImgInit(o.backgroundImg)"
															>
																<div
																	class="classNameDiv"
																	@click="toClassRoom(o)"
																>
																	<h3 class="className">{{ o.className }}</h3>
																</div>
																<div class="roleIdArea">
																	<el-tag
																		class="class_id"
																		size="small"
																		type="info"
																		>代碼: {{ o.id }}</el-tag
																	>
																	<div class="role">
																		<span class="me-1"
																			>我的角色<i
																				class="fal fa-caret-right ms-1"
																			></i
																		></span>
																		<el-tag
																			size="small"
																			v-if="
																				o.role == 'Teacher' ||
																				o.role == 'Student' ||
																				o.role == 'Parent'
																			"
																			type="info"
																		>
																			<span v-if="o.role == 'Teacher'"
																				>老師</span
																			>
																			<span v-else-if="o.role == 'Student'"
																				>學生</span
																			>
																			<span v-else-if="o.role == 'Parent'"
																				>家長</span
																			>
																		</el-tag>
																		<el-tag
																			v-if="o.isOwner"
																			size="small"
																			type="info"
																			style="border: none"
																		>
																			<span>建立者</span>
																		</el-tag>
																	</div>
																</div>
															</div>

															<div class="classCreator">
																<template
																	v-if="
																		o.role == 'Student' || o.role == 'Parent'
																	"
																>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			老師
																		</el-tag>
																	</div>
																	<el-avatar
																		size="small"
																		:src="o.teacher.avatar"
																		class="me-1"
																	></el-avatar>
																	<p class="creatorName">
																		{{ o.teacher.name }}
																	</p>
																</template>
																<template v-else>
																	<div class="d-flex align-items-center">
																		<el-tag size="small" class="me-1">
																			學生
																		</el-tag>
																	</div>
																	<el-avatar
																		v-for="s in o.studentAvatarAndNames"
																		:key="s"
																		size="small"
																		class="me-1"
																		:src="s.avatar"
																	></el-avatar>
																</template>
															</div>

															<div class="tagsArea" v-if="o.tags != null">
																<el-button
																	v-for="item in o.tags"
																	:key="item"
																	size="small"
																	class="mb-1"
																>
																	{{ getUserTagsName(item) }}
																</el-button>
															</div>
															<div class="otherTool">
																<div>
																	<i
																		v-if="setPermission(o.permission)"
																		class="fa-sharp fa-light fa-reply me-1"
																		title="復原"
																		@click="comebackClassRoom(o)"
																	></i>
																	<i
																		v-if="setPermission(o.permission)"
																		class="fa-sharp fa-light fa-trash me-1"
																		title="永久刪除"
																		@click="deleteClassRoomSoft(o)"
																	></i>
																</div>
																<div class="role">
																	<el-tag
																		v-if="o.isCheckInToday"
																		class="ms-2"
																		type="info"
																		style="border: none"
																	>
																		<span>今日已打卡</span>
																	</el-tag>
																</div>
															</div>
														</div>
													</el-card>
												</el-col>
											</template>
										</el-row>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-main>
				<router-view v-else />
				<el-dialog v-model="dialogFormVisible" title="新增標籤" width="80%">
					<el-form :model="tagForm">
						<el-form-item label="名稱">
							<el-input
								v-model="tagForm.name"
								autocomplete="off"
								placeholder="請輸入標籤名稱"
							/>
							<div class="mt-1">
								<el-button type="primary" size="small" @click="addTag"
									>新增標籤</el-button
								>
							</div>
						</el-form-item>
						<el-form-item label="標籤">
							<el-checkbox-group v-model="tagForm.selected" @change="selectTag">
								<el-checkbox
									v-for="item in usersTags"
									:key="item.id"
									:label="item.id"
									>{{ item.name }}</el-checkbox
								>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>
		</div>
	</el-container>
</template>

<script>
import ClassRoomNavbar from "../../../layout/Navbar.vue";
import TagLeftBar from "../../../components/ChargeMeContactBook/ClassRoom/TagLeftBar.vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import Storage from "../../../models/storage";
const dayjs = require("dayjs");

export default {
	name: "ClassRoomCenter",
	components: {
		VuePictureCropper,
		ClassRoomNavbar,
		TagLeftBar,
	},
	computed: {
		getShowLeftBar() {
			return (
				((this.$route.name == "ChargeMeContactBook_ContactBook" ||
					this.$route.name == "ChargeMeContactBook_ContactBookView") &&
					this.$store.state.classRoomTabActiveName == "second") ||
				(this.$route.name == "ChargeMeContactBook_ClassRoomCenter" &&
					!this.$store.state.classRoomCenterActiveMode)
			);
		},
		getShowLeftBarStyle() {
			return this.getShowLeftBar ? "48px" : "none";
		},
		filteredData() {
			let classRooms = this.classRooms;
			if (this.selectRole != "") {
				if (this.selectRole != "All") {
					if (this.selectRole == "isOwner") {
						classRooms = classRooms.filter((x) => x.isOwner);
					} else {
						classRooms = classRooms.filter((x) => x.role == this.selectRole);
					}
				}
			}

			return classRooms.filter(
				(x) =>
					x.tags.includes(this.$store.state.searchTerm) ||
					x.className.includes(this.$store.state.searchTerm) ||
					x.creator.name.includes(this.$store.state.searchTerm)
			);
		},
		isClassRoomCenterShow() {
			return (
				this.$route.path === "/ChargeMeContactBook/ClassRoomCenter" ||
				this.$route.path === "/ChargeMeContactBook/ClassRoomCenter/"
			);
		},
		filterRole() {
			let result = "";
			switch (this.selectRole) {
				case "Student":
					result = "學生";
					break;
				case "Teacher":
					result = "老師";
					break;
				case "Parent":
					result = "家長";
					break;
				case "isOwner":
					result = "建立";
					break;
				default:
					result = "全部";
					break;
			}
			return result;
		},
	},
	async mounted() {
		this.log_PageInfo("ClassRoomCenter", "2");
		if (this.$route.name == "ChargeMeContactBook_ClassRoomCenter") {
			Storage.set("breadcrumb_from", {
				first: "ClassRoomCenter",
			});
		}
		// init usersTags
		this.$store.state.usersTags = [];
		this.$store.state.selectTagId = "All";

		// 是否要打開即時通訊
		console.log(this.$route);
		if (
			this.$route.query.openMessageWindow != undefined &&
			this.$route.query.openMessageWindow == "1"
		) {
			this.$store.state.isMessageShow = true;
		}
	},
	async created() {
		await this.initData();
	},
	data() {
		return {
			isloaded: false,
			searchApplyClassInput: "",
			IsFilterClass: false,
			showApplyBtn: false,
			IsSearchingClass: false,
			isLoading: true,
			classSearchData: [],
			searchAddClassInput: "",
			backupImage: "",
			defaultBgImages: [
				"https://chargemestorage.blob.core.windows.net/shared-image/ClassBackground.jpg",
				"https://chargemestorage.blob.core.windows.net/shared-image/ClassBackground-1.jpg",
				"https://chargemestorage.blob.core.windows.net/shared-image/ClassBackground-2.jpg",
			],
			setClassObj: "",
			dataFile: "",
			classBackgroundDialog: false,
			imageToCrop: null,
			searchStr: "",
			activeCenterMode: false,
			selectClass: null,
			usersTags: [],
			tagForm: {
				name: "",
				selected: [],
			},
			dialogFormVisible: false,
			archive: false,
			predefineColors: [
				"#FFFFFF",
				"#e6b8af",
				"#f4cccc",
				"#fce5cd",
				"#fff2cc",
				"#d9ead3",
				"#d0e0e3",
				"#c9daf8",
				"#cfe2f3",
				"#d9d2e9",
				"#ead1dc",
			],
			classRooms: [],
			activeName: "classList",
			excludeTags: ["trash", "inbox", "editTag", "All", ""],
			payCheckData: {
				classRoomPaymentInfoList: [],
				classRoomCheckInfoList: [],
				studentList: [],
			},
			selectRole: "",
		};
	},
	methods: {
		rolehandleClick(command) {
			this.selectRole = command;
		},
		handleSelectClass() {
			this.showApplyBtn = true;
		},
		applyToClass() {
			const vm = this;
			var isExist = vm.classSearchData.some(
				(x) => x.value == vm.searchApplyClassInput
			);
			if (!this.IsSearchingClass) {
				this.IsSearchingClass = true;
				return;
			}

			var memberId = vm.$store.state.auth.user.id;
			if (isExist == false || vm.searchApplyClassInput == "") {
				vm.notify("error", `無此課程資訊`);
				return;
			}

			const data = {
				ClassRoomId: vm.searchApplyClassInput,
				MemberId: memberId,
				Role: "Applicant",
			};
			//axios
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/ClassRoom/Member`,
				data
			)
				.then(() => {
					vm.notify("success", `已成功申請!`);
					vm.IsSearchingClass = false;
					vm.showApplyBtn = false;
					vm.searchApplyClassInput = "";
				})
				.catch((err) => {
					console.log(err.response.data);
					vm.notify("error", `新增失敗！`);
				});
		},
		querySearch(queryString, cb) {
			var results = queryString
				? this.classSearchData.filter(this.createFilter(queryString))
				: this.members.memberData;
			cb(results);
		},
		createFilter(queryString) {
			return (r) => {
				return (
					r.value
						.toString()
						.toLowerCase()
						.indexOf(queryString.toLowerCase()) === 0 ||
					r.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
				);
			};
		},
		backgroundImgInit(img) {
			// console.log(img);
			if (img == "") {
				return "";
			}
			return {
				"background-image": `url(${img})`,
			};
		},
		setClassImgBtn(o) {
			let vm = this;
			vm.setClassObj = o;
			vm.backupImage = o.backgroundImg;
			vm.classBackgroundDialog = true;
		},
		setClassImage() {
			const vm = this;
			let o = vm.setClassObj;

			if (
				vm.setClassObj.backgroundImg == "" ||
				vm.defaultBgImages.includes(vm.setClassObj.backgroundImg)
			) {
				const putData = {
					classRoomId: o.id,
					classRoomName: o.className,
					backgroundColor: o.backgroundColor,
					memberId: vm.currentUser().id,
					isArchive: o.isArchive,
					isTop: o.isTop,
					tags: o.tags,
					backgroundImage: vm.setClassObj.backgroundImg,
				};
				vm.editClassCommon(putData, "課程背景");
				o.backgroundImg = vm.setClassObj.backgroundImg;
				vm.backupImage = vm.setClassObj.backgroundImg;
			} else {
				if (!vm.imageRestrict(vm.dataFile)) {
					vm.notify("error", "圖片格式不支援，僅限 jpg / jpeg / png！");
					return;
				}

				let formData = new FormData();
				formData.append("image", vm.dataFile.file);
				vm.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/images/image`,
					formData
				)
					.then((res) => {
						let newImageVal = res.data;
						// alert(newImageVal);
						const putData = {
							classRoomId: o.id,
							classRoomName: o.className,
							backgroundColor: o.backgroundColor,
							memberId: vm.currentUser().id,
							isArchive: o.isArchive,
							isTop: o.isTop,
							tags: o.tags,
							backgroundImage: newImageVal,
						};
						vm.editClassCommon(putData, "課程背景");
						o.backgroundImg = newImageVal;
						vm.backupImage = o.backgroundImg;
					})
					.catch((err) => {
						console.log(err);
						this.notify("error", "封面圖片上傳失敗！");
					});
			}
			vm.imageToCrop = "";
			vm.classBackgroundDialog = false;
		},
		async getCropResult() {
			let vm = this;
			if (vm.imageToCrop == null || vm.imageToCrop == "") {
				this.notify("error", "尚無上傳圖片！");
				return;
			}
			// 获取生成的base64图片地址
			const base64 = cropper.getDataURL();

			// 获取生成的blob文件信息
			const blob = await cropper.getBlob();

			// 获取生成的file文件信息
			const file = await cropper.getFile();

			const dataObject = { base64, blob, file };
			// 把base64赋给结果展示区
			this.dataFile = dataObject;
			this.dataURL = base64;
			vm.setClassObj.backgroundImg = URL.createObjectURL(blob);
			// this.blobURL = URL.createObjectURL(blob);
			// 隐藏裁切弹窗
			this.isShowDialog = false;
		},
		uploadImage(event) {
			let vm = this;
			vm.imageToCrop = "";

			let e = event.raw;
			const file = e;

			// 通过 FileReader 将图片数据转换为 URL
			const reader = new FileReader();
			reader.onload = () => {
				vm.imageToCrop = reader.result;
			};
			reader.readAsDataURL(file);
		},
		emptyClassRoomShow() {
			let canShow = true;
			this.classRooms.forEach((x) => {
				if (!x.isTempDeleted) canShow = false;
			});
			this.isEmptyShow = canShow;
		},
		filterData(str) {
			this.$store.state.searchTerm = str;
		},
		initData() {
			const vm = this;
			vm.isloaded = false;
			var url = "";
			if (vm.$route.name != "ChargeMeContactBook_ClassRoomCenter") return;
			if (vm.$route.query.ReferralCode == undefined)
				url = `${process.env.VUE_APP_URL_API}/ClassRoomCenter`;
			else
				url = `${process.env.VUE_APP_URL_API}/ClassRoomCenter?ReferralCode=${vm.$route.query.ReferralCode}`;

			vm.fetchAPI("get", url)
				.then((res) => {
					console.log(res.data);
					vm.classRooms = [];
					if (res.data.length != 0) {
						res.data.classRoomList.forEach((x) => {
							vm.classRooms.push({
								id: x.classRoomId,
								className: x.classRoomName,
								backgroundColor: x.backgroundColor,
								teacher: {
									name: x.teacherName,
									avatar: x.teacherAvatar,
								},
								studentAvatarAndNames: x.studentAvatarAndNames,
								isTop: x.isTop,
								isArchive: x.isArchive,
								tags: x.tags,
								isTempDeleted: x.isTempDeleted,
								permission: x.permission,
								role: x.role,
								originClassTime: x.classTime,
								firstStudentName: x.firstStudentName,
								onlineLink: x.onlineLink,
								onsiteAddress: x.onsiteAddress,
								onsiteClassRoomName: x.onsiteClassRoomName,
								classTime: x.classTime,
								isOwner: x.isOwner,
								isCheckInToday: x.isCheckInToday,
								backgroundImg: x.backgroundImage,
							});
						});
						res.data.allClassRoomList.forEach((x) => {
							vm.classSearchData.push({
								value: x.classRoomId,
								name: x.classRoomName,
								BgImg: x.backgroundImage,
								BgColor: x.backgroundColor,
							});
						});
						vm.usersTags = res.data.userTagList;
					}
					vm.emptyClassRoomShow();
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					vm.isloaded = true;
				});

			// vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoomCenter/UserTag`)
			//   .then((res) => {
			//     vm.usersTags = res.data;
			//   })
			//   .catch((err) => {
			//     console.log(err);
			//   });
		},
		colorChange(o) {
			const vm = this;
			const data = {
				classRoomId: o.id,
				classRoomName: o.className,
				backgroundColor: o.backgroundColor,
				backgroundImage: o.backgroundImg,
				memberId: vm.currentUser().id,
				isArchive: o.isArchive,
				isTop: o.isTop,
				tags: o.tags,
			};
			vm.editClassCommon(data, "課程顏色變更");
		},
		async addClassRoom() {
			const vm = this;

			var isMembership = await vm.isMembershipExpired();
			// 確認會籍
			if (!isMembership) {
				// vm.notify("error", `會籍到期，請購買會籍後方可新增聯絡簿！`);
				vm.$confirm(
					"親愛的會員，您的會籍已到期，需要升級為付費會員才能繼續使用，若原本即為付費會員，也請續約繳費，以延續付費功能！",
					"會籍到期",
					{
						confirmButtonText: "付費",
						cancelButtonText: "取消",
						type: "warning",
					}
				)
					.then(() => {
						this.$router.push("/ChargeMeContactBook/payment");
					})
					.catch(() => {
						return;
					});
			} else {
				// 確認開課上限
				var isClassCountLimit = await vm.isClassCountLimit();
				if (!isClassCountLimit) {
					vm.$confirm(
						"親愛的會員，您已達到開課上限堂數，需要升級為專業會員，或者維持開課堂數(課程選單+封存+垃圾桶)小於上限堂數，方可繼續開課！",
						"開課堂數超過上限",
						{
							confirmButtonText: "升級",
							cancelButtonText: "取消",
							type: "warning",
						}
					)
						.then(() => {
							this.$router.push("/ChargeMeContactBook/payment?memberLevel=Pro");
						})
						.catch(() => {
							return;
						});
				} else {
					vm.$prompt("請輸入課程名稱", "新增課程", {
						confirmButtonText: "新增",
						cancelButtonText: "取消",
					})
						.then(({ value }) => {
							//axios
							const data = {
								classRoomName: value,
								memberId: vm.currentUser().id,
								BackgroundImage: vm.defaultBgImages[0],
							};
							vm.fetchAPI(
								"post",
								`${process.env.VUE_APP_URL_API}/ClassRoomCenter`,
								data
							)
								.then((res) => {
									// console.log(res.data);
									vm.classRooms.push({
										id: res.data.classRoomId,
										className: res.data.classRoomName,
										backgroundColor: res.data.backgroundColor,
										backgroundImg: res.data.backgroundImage,
										teacher: {
											name: res.data.createdUserName,
											avatar: res.data.createdUserAvatar,
										},
										isTop: res.data.isTop,
										isArchive: res.data.isArchive,
										tags: res.data.tags,
										permission: res.data.permission,
										isTempDeleted: false,
										isOwner: true,
										role: "Teacher",
									});
									vm.emptyClassRoomShow();
									vm.$message({
										type: "success",
										message: "新增完成",
									});
									vm.isEmptyShow = false;
								})
								.catch((err) => {
									console.log(err.response.data);
									vm.notify("error", `新增失敗！`);
								});
						})
						.catch(() => {
							vm.$message({
								type: "info",
								message: "已取消",
							});
						});
				}
			}
		},
		editClassRoom(o) {
			const vm = this;
			this.$prompt(`請輸入課程名稱`, "修改名稱", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				inputValue: o.className,
			})
				.then(({ value }) => {
					if (value == "" || value == null) {
						vm.notify("error", `課程名稱不可為空白！`);
						return;
					}
					//axios
					const data = {
						classRoomId: o.id,
						classRoomName: value,
						backgroundColor: o.backgroundColor,
						backgroundImage: o.backgroundImg,
						memberId: vm.currentUser().id,
						isArchive: o.isArchive,
						isTop: o.isTop,
						tags: o.tags,
					};
					vm.editClassCommon(data, "修改");
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		archiveClassRoom(o) {
			const vm = this;
			console.log(o);
			const data = {
				classRoomId: o.id,
				classRoomName: o.className,
				backgroundColor: o.backgroundColor,
				backgroundImage: o.backgroundImg,
				memberId: vm.currentUser().id,
				isArchive: !o.isArchive,
				isTop: o.isTop,
				tags: o.tags,
			};
			if (data.isTop == true) data.isTop = false;
			vm.editClassCommon(data, "封存", o.isArchive);
		},
		topClassRoom(o) {
			const vm = this;
			const data = {
				classRoomId: o.id,
				classRoomName: o.className,
				backgroundColor: o.backgroundColor,
				backgroundImage: o.backgroundImg,
				memberId: vm.currentUser().id,
				isArchive: o.isArchive,
				isTop: !o.isTop,
				tags: o.tags,
			};
			if (data.isArchive == true) data.isArchive = false;
			vm.editClassCommon(data, "固定", o.isTop);
		},
		editClassCommon(o, method, status) {
			const vm = this;
			console.log(o.backgroundImg);
			vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoomCenter`, o)
				.then((res) => {
					console.log(res.data);
					var classRoom = vm.classRooms.find((x) => x.id == o.classRoomId);
					classRoom.className = o.classRoomName;
					classRoom.isArchive = o.isArchive;
					classRoom.isTop = o.isTop;
					classRoom.tags = o.tags;
					classRoom.BackgroundImage = o.backgroundImg;

					if (!status) {
						vm.$message({
							type: "success",
							message: `${method}完成！`,
						});
					} else {
						vm.$message({
							type: "success",
							message: `取消${method}完成！`,
						});
					}
				})
				.catch((err) => {
					console.log(err);
					vm.notify("error", `${method}失敗！`);
				});
		},
		copyClassRoom(o) {
			const vm = this;
			this.$confirm(`確定要複製此課程嗎? [${o.className}]`, "提示", {
				confirmButtonText: "複製",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					//axios
					const data = {
						classRoomName: `${o.className}-複製`,
						memberId: vm.currentUser().id,
						backgroundColor: o.backgroundColor,
					};
					vm.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/ClassRoomCenter`,
						data
					)
						.then((res) => {
							// console.log(res.data);
							vm.classRooms.push({
								id: res.data.classRoomId,
								className: res.data.classRoomName,
								backgroundColor: res.data.backgroundColor,
								teacher: {
									name: res.data.createdUserName,
									avatar: res.data.createdUserAvatar,
								},
								studentAvatarAndNames: res.data.studentAvatarAndNames,
								isTop: res.data.isTop,
								isArchive: res.data.isArchive,
								tags: res.data.tags,
								permission: res.data.permission,
							});
							vm.$message({
								type: "success",
								message: "複製成功",
							});
						})
						.catch((err) => {
							console.log(err.response.data);
							vm.notify("error", `複製失敗！`);
						});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		deleteClassRoom(o) {
			const vm = this;
			let data = {
				classRoomId: o.id,
			};
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/ClassRoomCenter/DeleteClassRoom`,
				data
			)
				.then(() => {
					this.$message({
						type: "success",
						message: "移至垃圾桶成功",
					});
					var classRoom = this.classRooms.find((item) => item.id == o.id);
					classRoom.isTempDeleted = true;
				})
				.catch((err) => {
					console.log(err);
					vm.notify("error", "移至垃圾桶失敗！");
				});
		},
		deleteClassRoomSoft(o) {
			const vm = this;
			this.$confirm(`確定要永久刪除此課程嗎? [${o.className}] `, "永久刪除", {
				confirmButtonText: "刪除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					let data = {
						classRoomId: o.id,
					};
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/ClassRoomCenter/DeleteClassRoomSoft`,
						data
					)
						.then(() => {
							this.$message({
								type: "success",
								message: "永久刪除成功",
							});
							this.classRooms = this.classRooms.filter(
								(item) => item.id !== o.id
							);
						})
						.catch((err) => {
							console.log(err);
							vm.notify("error", "永久刪除失敗！");
						});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "取消永久刪除",
					});
				});
		},
		comebackClassRoom(o) {
			const vm = this;
			this.$confirm(`確定要復原此課程嗎? [${o.className}] `, "提示", {
				confirmButtonText: "復原",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					//axios
					let data = {
						classRoomId: o.id,
					};

					vm.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/ClassRoomCenter/ComebackClassRoom`,
						data
					)
						.then((res) => {
							if (res.status == 200) {
								this.$message({
									type: "success",
									message: "已復原",
								});
								this.classRooms = this.classRooms.filter(
									(item) => item.id !== o.id
								);
							}
						})
						.catch((err) => {
							console.log(err);
							vm.notify("error", "復原失敗！");
						});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "取消復原",
					});
				});
		},
		rerenderPage() {
			this.classRooms = [];
			this.initData();
		},
		toClassRoom(o) {
			this.$router.push({
				name: "ChargeMeContactBook_ClassRoom",
				params: {
					id: o.id,
					className: o.className,
				},
			});
		},
		toClassRoomByActiveName(o, activeName) {
			this.$store.state.classRoomTabActiveName = activeName;

			this.$router.push({
				name: "ChargeMeContactBook_ClassRoom",
				params: {
					id: o.id,
					className: o.className,
					activeName: activeName,
				},
			});
		},
		addTag() {
			let vm = this;
			let o = vm.selectClass;

			if (vm.usersTags.find((x) => x.name == vm.tagForm.name) != undefined) {
				vm.$message({
					type: "error",
					message: "標籤名稱重複！",
				});
				return;
			}

			if (vm.tagForm.name != "") {
				let tagName = vm.tagForm.name;
				let data = {
					classId: o.id,
					tagName: tagName,
				};
				vm.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/ClassRoomCenter/ClassTag`,
					data
				)
					.then((res) => {
						let insertData = { id: res.data.tagId, name: tagName };
						vm.usersTags.unshift(insertData);
					})
					.catch((err) => {
						console.log(err);
					});
				vm.tagForm.name = "";
			}
		},
		selectTag() {
			let vm = this;
			let o = vm.selectClass;

			const data = {
				classRoomId: o.id,
				classRoomName: o.className,
				backgroundColor: o.backgroundColor,
				backgroundImage: o.backgroundImg,
				memberId: vm.currentUser().id,
				isArchive: o.isArchive,
				isTop: o.isTop,
				tags: vm.tagForm.selected,
			};
			vm.editClassCommon(data, "更新標籤", o.isArchive);

			vm.classRooms.filter((x) => x.id == vm.selectClass.id)[0].tags =
				vm.tagForm.selected;
		},
		getUserTagsName(item) {
			if (this.usersTags.find((x) => x.id == item) == undefined) return;
			return this.usersTags.length == 0
				? ""
				: this.usersTags.find((x) => x.id == item).name;
		},
		addPayment(item) {
			if (item.length == 0) return 0;
			var result = 0;
			item.forEach((x) => {
				result += Number(x.classRoomNumber);
			});
			return result;
		},
		createClassRoomCheckIn(o) {
			const vm = this;
			console.log(o);
			vm.$confirm(
				`打卡日期：${dayjs().format("YYYY/MM/DD HH:mm")} `,
				`課程打卡 - ${o.className}`,
				{
					confirmButtonText: "確認",
					cancelButtonText: "取消",
					type: "warning",
				}
			)
				.then(() => {
					vm.fetchAPI(
						"get",
						`${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/${o.id}/${
							vm.currentUser().id
						}`
					)
						.then((res) => {
							// console.log(res.data);
							if (Object.keys(res.data).length != 0) {
								Object.assign(vm.payCheckData, res.data);
							}
							if (
								this.addPayment(
									vm.payCheckData.studentList[0].classRoomPaymentInfoList
								) == vm.payCheckData.studentList[0].classRoomCheckInList.length
							) {
								this.notify("error", `無法打卡，請確認是否還有剩餘堂數！`);
								return;
							} else {
								var day = dayjs();
								const data = {
									ClassRoomId: o.id,
									StudentId: vm.currentUser().id,
									CheckedTime: day.add(8, "hour"),
									IsDueTime: false,
								};
								vm.fetchAPI(
									"post",
									`${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/CheckIn`,
									data
								)
									.then((res) => {
										console.log(res.data);
										vm.notify("success", `打卡成功！`);
										var classRoom = this.classRooms.find(
											(item) => item.id == o.id
										);
										classRoom.isCheckInToday = true;
									})
									.catch((err) => {
										vm.notify("error", `打卡失敗，${err.response.data.Msg}!`);
									});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "取消打卡",
					});
				});
		},
	},
	watch: {
		usersTags(val) {
			this.$store.state.usersTags = val;
		},
		searchAddClassInput(val) {
			this.filterData(val);
		},
		classRooms(val) {
			if (val.length == 0) this.isEmptyShow = false;
			this.emptyClassRoomShow();
		},
		activeCenterMode(val) {
			this.$store.state.classRoomCenterActiveMode = val;
		},
		dialogFormVisible: function () {
			let vm = this;
			if (vm.dialogFormVisible) {
				let targetTags = vm.classRooms.filter(
					(x) => x.id == vm.selectClass.id
				)[0].tags;

				// console.log(targetTags);
				vm.tagForm.selected = targetTags;
			}
		},
		"$store.state.selectTagId": function (newVal) {
			let vm = this;
			const isNumber = (n) => typeof n === "number" || n instanceof Number;
			if (vm.$route.name != "ChargeMeContactBook_ClassRoomCenter") return false;
			if (isNumber(newVal)) {
				vm.classRooms = vm.classRooms.filter(
					(x) => x.tags.indexOf(newVal) != -1
				);
				vm.fetchAPI(
					"get",
					`${process.env.VUE_APP_URL_API}/ClassRoomCenter/${
						vm.currentUser().id
					}/${newVal}`
				)
					.then((res) => {
						vm.classRooms = [];
						console.log(res);
						if (res.data.length != 0) {
							res.data.forEach((x) => {
								vm.classRooms.push({
									id: x.classRoomId,
									className: x.classRoomName,
									backgroundColor: x.backgroundColor,
									teacher: {
										name: x.teacherName,
										avatar: x.teacherAvatar,
									},
									isTop: x.isTop,
									isArchive: x.isArchive,
									tags: x.tags,
									permission: x.permission,
									backgroundImg: x.backgroundImage,
								});
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				vm.classRooms = [];
				vm.initData();
			}
		},
		"$route.name"(newName) {
			if (newName == "ChargeMeContactBook_ClassRoomCenter") {
				this.initData();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.searchClass {
	padding: 0.5rem;
}
.colorCircle {
	width: 20px;
	height: 20px;
	border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-input__wrapper {
	outline: none;
	transition: border-color 0.2s ease-in-out;
}
::v-deep .el-color-dropdown__main-wrapper {
	display: none !important;
}
.defaultBgImage {
	cursor: pointer;
}
.viewCrop {
	width: 300px;
	margin: auto;
}
.modal_class {
	background-color: rgba(0, 0, 0, 0);
}
.fa-thumbtack {
	text-shadow: 0.1em 0.1em 0.2em #999;
}
.fa-thumbtack,
.fl-thumbtack {
	cursor: pointer;
	transform: rotate(45deg);
}
.list-leave-active {
	position: absolute;
}
.list-enter-from {
	transform: translateY(-20px);
}
.list-leave-to {
	transform: translateY(20px);
}
a:hover {
	text-decoration: underline;
	text-decoration-color: white;
}
.fa-fill-drip {
	color: #999;
}
.otherTool i:hover,
.fa-bars:hover {
	transition: 0.1s;
	cursor: pointer;
	font-weight: 900;
}
.el-dropdown-link:hover {
	background: $main_color;
}
.fa-ellipsis-v:hover {
	color: #e0e0e0;
}
.classCard {
	display: flex;
	flex-direction: column;
	.classLine {
		height: 3px;
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
	}
	.classHeader {
		overflow: hidden;
		background-color: #ffffff;
		margin: 0.5rem;
		padding: 1.5rem 0.5rem;
		margin-bottom: 0;
		border-radius: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		.className {
			color: #5f6368;
			text-align: left;
			font-size: 1.1rem;
			font-weight: 400;
			letter-spacing: -0.05em;
			text-decoration: dotted !important;
		}
		.classNameDiv {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.classCreator {
		text-align: left;
		padding: 0.5rem 1rem;
		display: flex;
		align-items: center;
		font-size: small;
		.creatorName {
			color: #5f6368;
		}
	}
	.otherTool {
		color: #999;
		padding: 0rem 0.5rem;
		padding-bottom: 0.5rem;
		text-align: right;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.el-dropdown {
			margin-right: 0.2rem;
		}
		&:hover {
			cursor: pointer;
		}
		div {
			i {
				border-radius: 99em;
				text-align: center;
				align-items: center;
				&:hover {
					background: $main_color;
					border-radius: 99em;
					color: white;
				}
			}
		}
	}
}
.otherTool > div > i,
.el-dropdown-link > i {
	background-color: #ffffff;
	border-radius: 99em;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	color: rgb(81, 72, 50);

	cursor: pointer;
}
.otherTool > div > i {
	margin-right: 0.2rem;
}
.tagsArea {
	padding-left: 1rem;
	padding-right: 1rem;
	text-align: start;
}
.roleIdArea {
	color: #453d26;
	font-size: xx-small;
	position: absolute;
	padding-left: 1px;
	padding-right: 16px;
	bottom: 0px;
	z-index: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.tagsArea {
	:deep .el-tag {
		border: none !important;
		background-color: #398d69 !important;
		color: #fff;
		margin-right: 0.25rem;
	}
}
.roleIdArea {
	.class_id {
		font-size: 10px;
		padding: 2px;
		height: 16px;
	}
	.role {
		:deep .el-tag {
			border: none !important;
			padding: 0;
			background-color: rgba(0, 0, 0, 0);
			color: #453d26;
		}
	}
}
.el-card {
	border-radius: 0.5rem;
	margin-bottom: 0.5rem;
	box-shadow: 1px 1px 3px 1px #e1e1e1;
	border: none;
}
.el-header {
	font-size: 1.2rem;
	display: flex;
	color: #5f6368;
	border-bottom: 1px solid #e0e0e0;
	text-align: center;
	padding: 1rem;
	align-items: center;
	justify-content: space-between;
	.logo {
		margin-left: 1rem;
	}
	.addClass {
		align-items: center;
		.el-dropdown-link {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 99em;
			text-align: center;
			line-height: 1.7;
			align-items: center;
			font-size: 1rem;
		}
		.el-dropdown-link:hover {
			border-radius: 99em;
		}
	}
}
.el-dropdown-link {
	border-radius: 99em;
}
.el-container {
	margin-bottom: 40px;
	display: block;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
	line-height: 260px;
}
.el-container:nth-child(7) .el-aside {
	line-height: 320px;
}
.el-col {
	border-radius: 4px;
}
.bg-purple-dark {
	background: #99a9bf;
}
.bg-purple {
	background: #d3dce6;
}
.bg-purple-light {
	background: #e5e9f2;
}
.grid-content {
	border-radius: 4px;
	min-height: 36px;
}
.row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
}
.time {
	font-size: 13px;
	color: #999;
}
.bottom {
	margin-top: 13px;
	line-height: 12px;
}
.button {
	padding: 0;
	float: right;
}
.image {
	width: 100%;
	display: block;
}
.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both;
}
.empty_class {
	span {
		margin-top: 2rem;
		font-size: 3rem;
	}
	i {
		font-size: 15rem;
	}
}
.tagLeftBar {
	height: 100%;
}
:deep .el-checkbox-group {
	text-align: left;
}

:deep .el-checkbox-group > .el-checkbox {
	width: 100%;
}
.centerBtn {
	border: 1px solid #453d26;
	background-color: #fff;
	padding: 0.75rem 0.5rem;
	color: #453d26 !important;
	box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
	&:hover {
		background-color: $main_color;
	}
}
.addCenterBtn {
	background-color: #ffefc8;
}
</style>
