<template>
	<div
		class="tagLeftBarContent"
		:style="{
			'box-shadow': $store.state.LeftBarContentShow
				? '5px 0 5px rgba(0, 0, 0, 0.05)'
				: '',
		}"
		@mouseover="$store.state.LeftBarContentShow = true"
	>
		<div
			class="tagLeftBarContentDiv"
			:class="{ IsMobileSize: $store.getters.mobileSize }"
		>
			<div class="tagAssociation" @click="handleSelect('All')">
				<span class="tagName">
					<div
						:class="{ tagBtnActive: $store.state.selectTagId == 'All' }"
						class="tagBtn"
					>
						<i class="far fa-tags"></i>
					</div>
					<span
						:class="{ tagBtnActive: $store.state.selectTagId == 'All' }"
						class="tagName_show"
						v-if="$store.state.LeftBarContentShow"
						>全部標籤</span
					>
				</span>
			</div>
			<div class="tagDiv">
				<div
					class="tagAssociation"
					v-for="(item, index) in usersTags"
					:key="index"
					@click="handleSelect(item.id)"
				>
					<span class="tagName">
						<div
							:class="{ tagBtnActive: $store.state.selectTagId == item.id }"
							class="tagBtn"
						>
							<i class="far fa-tag"></i>
						</div>
						<span
							:class="{ tagBtnActive: $store.state.selectTagId == item.id }"
							class="tagName_show"
							v-if="$store.state.LeftBarContentShow"
							>{{ item.name }}</span
						>
					</span>
				</div>
			</div>
			<div>
				<div class="tagAssociation" @click="editTagDialogVisible = true">
					<span class="tagName">
						<div class="tagBtn">
							<i class="fa-light fa-pen"></i>
						</div>
						<span class="tagName_show" v-if="$store.state.LeftBarContentShow"
							>編輯</span
						>
					</span>
				</div>
				<div
					class="tagAssociation"
					v-if="$route.name == 'ChargeMeContactBook_ClassRoomCenter'"
					index="inbox"
					@click="handleSelect('inbox')"
				>
					<span class="tagName">
						<div class="tagBtn">
							<i class="far fa-inbox-in"></i>
						</div>
						<span class="tagName_show" v-if="$store.state.LeftBarContentShow"
							>封存</span
						>
					</span>
				</div>
				<div
					class="tagAssociation"
					index="trash"
					@click="handleSelect('trash')"
				>
					<span class="tagName">
						<div @click="classRoomDrawer = false" class="tagBtn">
							<i class="fal fa-trash-alt"></i>
						</div>
						<span class="tagName_show" v-if="$store.state.LeftBarContentShow"
							>垃圾桶</span
						>
					</span>
				</div>
				<div
					v-if="$store.state.LeftBarContentShow"
					class="tagAssociation"
					index="trash"
					@click="$store.state.LeftBarContentShow = false"
				>
					<span class="tagName">
						<div @click="classRoomDrawer = false" class="tagBtn">
							<i class="fal fa-arrow-to-left"></i>
						</div>
						<span class="tagName_show" v-if="$store.state.LeftBarContentShow"
							>隱藏文字</span
						>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog">
		<el-dialog
			v-model="editTagDialogVisible"
			title="編輯標籤"
			:width="$store.getters.mobileSize ? '90%' : '600px'"
			center
			@close="resetTagDialog"
		>
			<template v-if="usersTags.length != 0">
				<div class="editTag">
					<div
						v-for="i in usersTags"
						:key="i"
						class="d-flex mb-3 editTagOption"
						:data-tagId="i.id"
						style="font-size: medium"
						@click="renameTag(i.id)"
					>
						<div
							class="d-flex align-items-center justify-content-between w-100"
						>
							<div class="w-100">
								<i
									v-if="renameId == i.id"
									class="trashTag far fa-trash-alt me-2"
									@click.stop.prevent="deleteConfirm(i.id)"
								></i>
								<i v-else class="fa-light fa-tag me-2"></i>
								<input
									class="renameInput"
									:id="'tagId_' + i.id"
									v-if="renameId == i.id"
									type="text"
									:value="i.name"
									style="width: 90%; border: none"
								/>
								<div v-else class="d-inline-block" style="width: 200px">
									{{ i.name }}
								</div>
							</div>
							<div>
								<el-button
									v-if="renameId == i.id"
									circle
									@click.stop.prevent="saveRename(i.id)"
								>
									<i style="width: 14px; height: 14px" class="fal fa-check"></i>
								</el-button>
								<el-button v-else circle>
									<i style="width: 14px; height: 14px" class="fal fa-pen"></i>
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div>目前尚無標籤。</div>
			</template>
			<template #footer>
				<span class="dialog-footer">
					<el-button
						type="primary"
						@click="
							editTagDialogVisible = false;
							saveRename(renameId);
						"
					>
						完成
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "TagLeftBar",
	data() {
		return {
			renameId: "",
			LeftBarContent: false,
			editTagDialogVisible: false,
			// usersTags: [],
		};
	},
	methods: {
		deleteConfirm(tid) {
			let vm = this;

			let confirmMsg = `將刪除這個標籤，並將該標籤從您所有的課程中移除，但您的課程並不會被刪除。`;
			let apiPath = `${process.env.VUE_APP_URL_API}/ClassRoomCenter/RemoveClassTag`;
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				confirmMsg = `將刪除這個標籤，並將該標籤從您所有的筆記中移除，但您的筆記並不會被刪除。`;
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RemoveArticleTag`;
			} else if (vm.$route.name == "ChargeMeContactBook_Note") {
				confirmMsg = `將刪除這個標籤，並將該標籤從您所有的記事本與知識點中移除，但您的記事本與知識點並不會被刪除。`;
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RemoveNoteTag`;
			}

			vm.$confirm(confirmMsg, "", {
				confirmButtonText: "確認",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					var o = {
						TagId: tid,
						NewName: "",
					};
					vm.fetchAPI("post", apiPath, o)
						.then((res) => {
							if (res.status == 200) {
								vm.$store.state.usersTags = vm.$store.state.usersTags.filter(
									(x) => x.id != tid
								);
								vm.$message({
									type: "success",
									message: `已刪除`,
								});
								vm.$emit("rerenderPage");
							} else {
								vm.$message({
									type: "warning",
									message: `刪除失敗`,
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {
					return;
				});
		},
		handleSelect(tagId) {
			//編輯不篩選
			if (tagId == "editTag") return;
			console.log(tagId);
			this.$store.state.selectTagId = tagId;
		},
		renameTag(tagId) {
			let vm = this;
			vm.renameId = tagId;
			setTimeout(() => {
				document.getElementById(`tagId_${tagId}`).focus();
			}, 10);
		},
		saveRename(tagId) {
			let vm = this;
			if (document.getElementById(`tagId_${tagId}`) == null) return;
			let newValue = document.getElementById(`tagId_${tagId}`).value;
			let sameTagNames = vm.usersTags.filter(
				(x) => x.name == newValue && x.id != tagId
			);
			if (sameTagNames.length > 0) {
				vm.$message({
					type: "info",
					message: `已經有重複名稱`,
				});
				return false;
			}

			var o = {
				TagId: tagId,
				NewName: newValue,
			};

			let apiPath = "";
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RenameArticleTag/`;
			} else if (vm.$route.name == "ChargeMeContactBook_Note") {
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RenameNoteTag/`;
			} else {
				apiPath = `${process.env.VUE_APP_URL_API}/Note/RenameNoteTag/`;
			}

			vm.fetchAPI("Post", apiPath, o)
				.then((res) => {
					if (res.status == 200) {
						vm.$message({
							type: "success",
							message: `編輯完成`,
						});
						vm.$emit("rerenderPage");
					}
				})
				.catch((err) => {
					console.log(err);
				});

			vm.usersTags.map((x) => {
				if (x.id == tagId) {
					x.name = newValue;
				}
			});
			vm.renameId = null;
		},
	},
	watch: {
		$route: "initial",
	},
	computed: {
		usersTags() {
			return this.$store.state.usersTags;
		},
	},
};
</script>

<style lang="scss" scoped>
.tagLeftBarContent {
	background-color: #fffdf8;
	position: fixed;
	z-index: 9;
	left: 0;
	height: 100%;
	text-align: center;
	padding: 0.25rem;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	.tagLeftBarContentDiv {
		height: 95vh !important;
		display: flex;
		flex-direction: column;
		&.IsMobileSize {
			height: 95vh !important;
		}
	}
	// box-shadow: 5px 0 5px rgba(0, 0, 0, 0.12);
	.tagAssociation {
		display: flex;
		align-content: center;
		margin-bottom: 5px;
		min-width: 2.5rem;
		cursor: pointer;

		.tagName {
			position: relative;
			width: 100%;
			height: 2.5rem;

			.tagBtn {
				width: 2.5rem;
				height: 2.5rem;
				line-height: 2.5rem;
				border-radius: 99em;
				position: absolute;
				left: 0;
				color: #77724b;
				&:hover {
					cursor: pointer;
				}
			}
			.tagBtnActive {
				background-color: #ffefc8 !important;
			}
			.tagName_show {
				// background-color: $main_color;
				line-height: 2.5rem;
				display: flex;
				padding-left: 3rem;
				padding-right: 1rem;
				width: 100%;
				border-radius: 0 99em 99em 0;
				color: #77724b;
				width: 100%;
				height: 2.5rem;
			}
		}
	}
}
.tagName:hover .tagName_show {
	background-color: rgba(0, 0, 0, 0.1) !important;
}
.tagDiv {
	overflow-y: auto;
	// max-height: 75vh;
	// &.IsMobileSize {
	//   max-height: 65vh;
	// }
}
.dialog :deep .el-dialog {
	.el-dialog__body {
		overflow-y: auto;
		max-height: 500px;
	}
}
</style>
